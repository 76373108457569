export default () => {
  const { gmapsApiKey = '', gmapsRegion = '', gmapsLanguage = '' } = window;
  if (!gmapsApiKey) {
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://maps.googleapis.com/maps/api/js?key=${gmapsApiKey}${
    gmapsRegion ? `&region=${gmapsRegion}` : ''
  }${
    gmapsLanguage ? `&language=${gmapsLanguage}` : ''
  }&callback=initMap&libraries=&v=weekly`;

  document.head.appendChild(script);
};
