export default class ScrollTo {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    const { element } = this;
    const menu = element.closest('.js-menu');
    const href = element.getAttribute('href');
    const target = document.querySelector(href);
    const header = document.querySelector('.js-header');

    element.addEventListener('click', (e) => {
      e.preventDefault();
      const top = target.offsetTop - header.offsetHeight;

      if (menu) {
        menu.Menu.toggleMenu();
      }
      window.scrollTo(0, top);
    });
  }
}
