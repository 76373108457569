import _debounce from 'lodash/debounce';

export default ({ callback, debounce = 250 }) => {
  let viewport = window.innerWidth;
  const onWindowResize = () => {
    const { innerWidth } = window;
    if (innerWidth !== viewport) {
      viewport = innerWidth;
      callback();
    }
  };

  window.addEventListener('resize', _debounce(onWindowResize, debounce));
  window.addEventListener('orientationchange', _debounce(onWindowResize, debounce));
};
