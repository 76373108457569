import Cookies from 'js-cookie';

export default class CookiesNotice {
  constructor(element) {
    this.element = element;
    this.content = element.querySelector('.js-cookies-content');
    this.btnAccept = element.querySelector('.js-cookies-accept');
    this.btnClose = element.querySelector('.js-cookies-close');

    this.setData();
    this.init();
  }

  setData() {
    const { transitionDuration = '300', expires = '30', showDelay = '500' } = this.element.dataset;
    this.transitionDuration = +transitionDuration;
    this.expires = +expires; // days
    this.showDelay = +showDelay; // ms
  }

  init() {
    const isAccepted = Cookies.get('cookies_accepted') === 'true';
    if (isAccepted) {
      return;
    }

    setTimeout(() => this.toggleMenu(), this.showDelay);

    this.btnAccept.addEventListener('click', () => this.accept());
    this.btnClose.addEventListener('click', () => this.toggleMenu());
  }

  toggleMenu() {
    const { element } = this;
    this.isActive = element.classList.contains('_active');

    if (this.isActive) {
      element.classList.remove('_active');
      setTimeout(() => {
        requestAnimationFrame(() => {
          element.classList.remove('_visible');
        });
      }, this.transitionDuration);
    } else {
      element.classList.add('_visible');
      element.getBoundingClientRect();
      requestAnimationFrame(() => {
        element.classList.add('_active');
      });
    }
  }

  accept() {
    Cookies.set('cookies_accepted', 'true', { expires: this.expires });

    this.toggleMenu();
  }
}
