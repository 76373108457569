import _debounce from 'lodash/debounce';

export default class Header {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    this.toggleFixed();

    window.addEventListener('scroll', _debounce(() => this.toggleFixed(), 250, {
      leading: true,
    }));
  }

  toggleFixed() {
    const { scrollY } = window;
    this.element.classList.toggle('_fixed', scrollY > 0);
  }
}
