export default ({ sm, lg }) => {
  const {
    innerWidth, LAYOUT_WIDTH, BREAKPOINTS,
  } = window;
  let size;

  switch (true) {
    case innerWidth < BREAKPOINTS.md:
      size = sm * (innerWidth / LAYOUT_WIDTH.sm);
      break;
    case innerWidth < BREAKPOINTS.fhd:
      size = lg * (innerWidth / LAYOUT_WIDTH.lg);
      break;
    default:
      size = lg;
  }
  return size;
};
