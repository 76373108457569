import {
  Swiper, Autoplay, Navigation, Pagination,
} from 'swiper/swiper.esm';
import _debounce from 'lodash/debounce';
import getFluidPx from '@/js/utils/getFluidPx';
import onViewportChange from '@/js/utils/onViewportChange';
import isInViewport from '@/js/utils/isInViewport';

Swiper.use([Autoplay, Navigation, Pagination]);

export default class Slider {
  constructor(element) {
    this.element = element;
    this.container = element.querySelector('.js-slider-container');
    this.progressBar = element.querySelector('.js-slider-progress-bar');

    this.setData();
    this.init();
  }

  setData() {
    const {
      speed = '600', autoplay = 'false', autoplayDelay = '5000', navigation = '', progressBar = 'false', mobileOnly = 'false',
    } = this.element.dataset;

    this.speed = +speed;
    this.autoplay = autoplay === 'true';
    this.autoplayDelay = +autoplayDelay;
    this.navigation = navigation;
    this.progressBar = progressBar === 'true';
    this.mobileOnly = mobileOnly === 'true';
  }

  init() {
    this.initSlider();

    onViewportChange({
      callback: () => this.initSlider(),
    });

    if (this.autoplay) {
      this.toggleAutoplay();

      this.container.addEventListener('mouseenter', () => this.stopAutoplay());
      this.container.addEventListener('mouseleave', () => this.toggleAutoplay());

      window.addEventListener('scroll', _debounce(() => this.toggleAutoplay(), 250, {
        leading: true,
      }));
    }
  }

  initSlider() {
    const { CONTAINER_OFFSET, GRID_GAP, BREAKPOINTS } = window;
    const isMobile = window.innerWidth < BREAKPOINTS.md;
    const slidesOffset = getFluidPx({
      sm: CONTAINER_OFFSET.sm,
      lg: CONTAINER_OFFSET.lg,
    });
    const spaceBetween = getFluidPx({
      sm: GRID_GAP,
      lg: GRID_GAP,
    });

    let navigation = false;
    if (this.navigation) {
      navigation = [...document.querySelectorAll(`.js-slider-nav[data-id="${this.navigation}"]`)];
      navigation = {
        nextEl: navigation.find(({ dataset: { direction } }) => direction === 'next'),
        prevEl: navigation.find(({ dataset: { direction } }) => direction === 'prev'),
      };
    }

    const autoplay = this.autoplay ? {
      delay: this.autoplayDelay,
    } : false;

    this.destroySlider();

    if (!this.mobileOnly || isMobile) {
      this.slider = new Swiper(this.container, {
        speed: this.speed,
        // autoHeight: true,
        spaceBetween,
        slidesPerView: 'auto',
        slidesOffsetBefore: slidesOffset,
        slidesOffsetAfter: slidesOffset,
        pagination: {
          el: '.swiper-pagination',
          type: this.progressBar && !isMobile ? 'progressbar' : 'bullets',
        },
        navigation,
        autoplay,
        on: {
          init: () => {
            this.container.classList.add('_initialized');
          },
          destroy: () => {
            this.container.classList.remove('_initialized');
          },
        },
      });
    }
  }

  destroySlider() {
    if (this.slider && !this.slider.destroyed) {
      this.slider.destroy();
    }
  }

  startAutoplay() {
    this.slider.autoplay.start();
  }

  stopAutoplay() {
    this.slider.autoplay.stop();
  }

  toggleAutoplay() {
    const isVisible = isInViewport(this.element);
    if (isVisible) {
      this.startAutoplay();
    } else {
      this.stopAutoplay();
    }
  }
}
