import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock';
import _debounce from 'lodash/debounce';

export default class Menu {
  constructor(element) {
    this.element = element;
    this.element.Menu = this;

    this.content = element.querySelector('.js-menu-content');
    this.trigger = document.querySelector('.js-menu-trigger');
    this.header = document.querySelector('.js-header');
    this.cookies = document.querySelector('.js-cookies');

    const { transitionDuration = '300' } = this.element.dataset;
    this.transitionDuration = +transitionDuration;

    this.init();
  }

  init() {
    this.setScrollGap();
    this.setTriggerGap();
    this.toggleFixed();

    this.trigger.addEventListener('click', () => this.toggleMenu());

    window.addEventListener('scroll', _debounce(() => this.toggleFixed(), 250, {
      leading: true,
    }));
  }

  toggleMenu() {
    clearTimeout(this.timer);
    const { element } = this;
    this.isActive = element.classList.contains('_active');

    if (this.isActive) {
      element.classList.remove('_active');
      this.timer = setTimeout(() => {
        requestAnimationFrame(() => {
          element.classList.remove('_visible');
        });
      }, this.transitionDuration);
    } else {
      this.resetSCrollPosition();
      element.classList.add('_visible');
      // eslint-disable-next-line no-unused-expressions
      element.getBoundingClientRect();
      requestAnimationFrame(() => {
        element.classList.add('_active');
      });
    }
    this.toggleTriggerState();
    this.toggleBodyScroll();
  }

  toggleTriggerState() {
    const {
      trigger, isActive, header, cookies, scrollBarGap,
    } = this;

    trigger.classList.toggle('_active', !isActive);
    header.style.right = isActive ? '0' : `${scrollBarGap}px`;
    cookies.style.paddingRight = isActive ? null : `${scrollBarGap}px`;
    this.setTriggerGap({ isActive: !this.isActive });
  }

  setScrollGap() {
    this.scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
  }

  setTriggerGap({ isActive = this.isActive } = {}) {
    // @TODO: refactor this crap
    const { innerWidth, BREAKPOINTS } = window;
    const x = innerWidth >= BREAKPOINTS.fhd ? `${this.scrollBarGap / 2}px` : '0';
    this.trigger.style.marginRight = isActive ? `${this.scrollBarGap}px` : x;
  }

  toggleBodyScroll() {
    const { isActive } = this;
    if (isActive) {
      enableBodyScroll(this.content);
    } else {
      disableBodyScroll(this.content, {
        reserveScrollBarGap: true,
      });
    }
  }

  resetSCrollPosition() {
    this.content.scrollTo(0, 0);
  }

  toggleFixed() {
    const { scrollY } = window;
    this.trigger.classList.toggle('_fixed', scrollY > 0);
  }
}
