import './polyfills';
import smoothscroll from 'smoothscroll-polyfill'; // for native dom scroll methods [IE]
// import LazyLoad from 'vanilla-lazyload';
import Header from './modules/header';
import Menu from './modules/menu';
import ScrollTo from './modules/scroll-to';
import Slider from './modules/slider';
import Map from './modules/map';
import Cookies from './modules/cookies';

smoothscroll.polyfill();

window.BREAKPOINTS = {
  md: 768,
  fhd: 1920,
};
window.LAYOUT_WIDTH = {
  sm: 375,
  lg: 1440,
};
window.CONTAINER_OFFSET = {
  sm: 16,
  lg: 135,
};
window.GRID_GAP = 16;

window.addEventListener('DOMContentLoaded', () => {
  // @TODO: configure webpack to handle data-src
  // window.LazyLoad = new LazyLoad({
  //   elements_selector: '.js-lazyload',
  // });

  document.querySelectorAll('.js-header').forEach((item) => {
    new Header(item);
  });
  document.querySelectorAll('.js-menu').forEach((item) => {
    new Menu(item);
  });
  document.querySelectorAll('.js-scroll-to').forEach((item) => {
    new ScrollTo(item);
  });
  document.querySelectorAll('.js-slider').forEach((item) => {
    new Slider(item);
  });
  document.querySelectorAll('.js-map').forEach((item) => {
    new Map(item);
  });
  document.querySelectorAll('.js-cookies').forEach((item) => {
    new Cookies(item);
  });
});
